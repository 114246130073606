import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage, useIntl, FormattedNumber } from "react-intl";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { CURRENCY } from "../constants";
import { getPriceFromState } from "../utils/product_utils";
import { ReactComponent as IconInfo } from "./icons/info.svg";

import PriceEur from "./PriceEur";

const Checkout = ({ product, state, onAddProduct, onSubmit }) => {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);

  const total = state
    .map(
      (productState) =>
        getPriceFromState(product, productState) * productState.qty
    )
    .reduce((a, b) => a + b, 0);

  const handleClose = () => setShowModal(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      setIsSubmitting(true);
      onSubmit({ email })
        .then((cartDetails) => {
          setIsSubmitting(false);
          setAlert({
            variant: "success",
            message: (
              <>
              <p>
                <FormattedMessage
                  id="cart.checkout.success"
                  values={{
                    a: (chunks) => (
                      <a
                        href={cartDetails.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </p>
                <p className="small">
                  <FormattedMessage id="cart.checkout.successDisclaimer" />
                </p>
              </>
            ),
          });
          window.open(cartDetails.url);
        })
        .catch((err) => {
          setAlert({
            variant: "danger",
            message: intl.formatMessage({
              id: "cart.checkout.error",
            }),
          });
          setIsSubmitting(false);
        });
    }
    setValidated(true);
  };

  return (
    <div className="checkout">
          <div className="text-right">
            <div className="mb-3">
              <Button className="uppercase" variant="outline-primary" size="sm" onClick={onAddProduct}>
                <strong>
                <FormattedMessage id="product.addNew" />
                </strong>
              </Button>
            </div>
            <div className="checkout__total">
            <FormattedMessage id="cart.total" />
            <strong>
            <FormattedNumber
              value={total}
              style="currency"
              currency={CURRENCY}
            />
              { CURRENCY === 'HRK' && <PriceEur value={total} /> }
            </strong>
            </div>
          </div>

      <Form validated={validated} noValidate onSubmit={handleSubmit}>
        <p>
          <FormattedMessage id="cart.email.description" />
        </p>


        <Form.Row>
<Col sm={6} lg={4}>
          <Form.Control
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            value={email}
            name="checkout_email"
            placeholder={intl.formatMessage({ id: "cart.email.placeholder" })}
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="cart.email.error" />
          </Form.Control.Feedback>
 </Col>
  </Form.Row>

        <Form.Group>
          <Form.Check type="checkbox" id="checkout_tos">
            <Form.Check.Input required type="checkbox" />
            <Form.Check.Label>
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "cart.field.tos" }),
                }}
              />
            </Form.Check.Label>
            <Form.Control.Feedback type="invalid">
              <FormattedMessage id="cart.field.tos.error" />
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>

        { false &&
        <Form.Group>
          <Form.Check type="checkbox" id="checkout_newsletter">
            <Form.Check.Input type="checkbox" />
            <Form.Check.Label>
              <FormattedMessage id="cart.field.newsletter" />
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>
        }

        {alert && (
          <Alert
            variant={alert.variant}
            onClose={() => setAlert(null)}
            dismissible
          >
            {alert.message}
          </Alert>
        )}

        <div className="checkout__submit">
          <Button className="mr-3" disabled={isSubmitting} type="submit" variant="primary">
            {isSubmitting ? (
              <FormattedMessage id="cart.checkout.button" />
            ) : (
              <FormattedMessage id="cart.checkout.button" />
            )}
          </Button>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>
              <>
              <FormattedMessage id="cart.checkout.button.info" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "cart.checkout.button.description",
                }),
              }}
            />
              </>

          </Tooltip>}
          >
            <IconInfo />
          </OverlayTrigger>
        </div>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="cart.checkout.button.info" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "cart.checkout.button.description",
                }),
              }}
            />
          </Modal.Body>
        </Modal>
      </Form>
    </div>
  );
};

export default Checkout;
