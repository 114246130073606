import React from "react";

import Carousel from "react-bootstrap/Carousel";
import reviews from "../constants";
import { ReactComponent as IconStar } from "./icons/star.svg";

const Stars = ({num}) => {
  return (
    <>
      { Array.from(Array(num).keys()).map((e, index) => <IconStar key={index} />) }
    </>
  )
}

const Reviews = () => {
  return (
    <Carousel className="reviews" controls={false} interval={7500}>
      {reviews.map((review, index) => (
        <Carousel.Item key={index}>
          <div className="review">
            <div className="review__name">{review.name}</div>
            <div className="review__stars"><Stars num={review.stars} /></div>
            <div className="review__content">{review.content}</div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Reviews;
