import React, { useReducer } from "react";
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

import Product from "./Product";
import product from "../products/ardeo";
import reducer, { initialState } from "../reducers/reducer";
import Checkout from "./Checkout";
import { submitOrder } from "../api";
import { getCartDetails } from "../utils/product_utils";


const ProductList = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setValue = (index, event, focusPreview, product, name, value) => {
    console.log(name, value);
    dispatch({
      index,
      type: "setValue",
      value: value || event.target.value,
      name: name || event.target.name.split("_")[1],
      focusPreview,
      product,
    });
  };

  const onSubmitOrder = async (values) => {
    const cartDetails = getCartDetails(values, product, state);
    await submitOrder(cartDetails);
    return cartDetails;
  };

  return (
    <div className="product-list">
      <ReactCSSTransitionGroup
        transitionName="product"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {state.map((productState, index) => (
          <Product
            prefix={`p${index}_`}
            key={index}
            setValue={(e) => setValue(index, e)}
            setValueAndFocusPreview={(e, name, value) =>
              setValue(index, e, true, product, name, value)
            }
            setImageIndex={(imageIndex) =>
              dispatch({
                type: "setValue",
                index: index,
                name: "imageIndex",
                value: imageIndex,
              })
            }
            state={productState}
            product={product}
            onDelete={
              state.length > 1
                ? () => dispatch({ type: "deleteProduct", index })
                : null
            }
          ></Product>
        ))}
      </ReactCSSTransitionGroup>
      <Checkout
        onAddProduct={(e) => dispatch({ type: "addProduct" })}
        state={state}
        product={product}
        onSubmit={onSubmitOrder}
      />
    </div>
  );
};

export default ProductList;
