import React from "react";
import { FormattedMessage } from "react-intl";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { COOKIES_INFO_URL } from "../constants";

const CookieBar = ({ onAccept }) => {
  return (
    <div class="cookie-bar">
      <Container>
        <FormattedMessage id="cookieBar.description" />{" "}
        <Button onClick={onAccept} class="cc-cookie-accept">
          <FormattedMessage id="cookieBar.agree" />
        </Button>{" "}
        <a
          href={COOKIES_INFO_URL}
          rel="noopener noreferrer"
          class="cc-cookie-about"
          target="_blank"
        >
          <FormattedMessage id="cookieBar.more" />
        </a>
      </Container>
    </div>
  );
};

export default CookieBar;
