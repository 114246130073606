import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as IconGlobe } from "../components/icons/globe.svg";
import { ReactComponent as IconPhone } from "../components/icons/telephone-fill.svg";
import Cookies from "js-cookie";

import { CURRENCY } from "../constants";
import ProductList from "../components/ProductList";
import Reviews from "../components/Reviews";
import Newsletter from "../components/Newsletter";
import {
  PAYMENT_TYPES,
  SOCIAL_ICONS,
  MANUALS_URL,
  LANGUAGE,
  YOUTUBE_VIDEO,
} from "../constants";
import CookieBar from "../components/CookieBar";

const CustomBlock = ({ title, content, image }) => {
  return (
    <Card>
      <Card.Img variant="top" src={`${process.env.PUBLIC_URL}${image}`} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{content}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const Home = () => {
  const [cookieAccept, setCookieAccept] = useState(true);

  useEffect(() => {
    console.log(Cookies.get("cookieAccept"));
    setCookieAccept(Cookies.get("cookieAccept"));
  }, []);

  const intl = useIntl();
  return (
    <div>
      <Navbar fixed="top" bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#section-intro">
            <img
              src={`${process.env.PUBLIC_URL}images/ardeo.svg`}
              alt={intl.formatMessage({ id: "nav.brand" })}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#section-why">
                <FormattedMessage id="nav.linkWhy" />
              </Nav.Link>
              <Nav.Link href="#section-personalize">
                <FormattedMessage id="nav.linkPersonalize" />
              </Nav.Link>
              <Nav.Link href="#section-reviews">
                <FormattedMessage id="nav.linkReviews" />
              </Nav.Link>
              <Nav.Link href="#section-contact">
                <FormattedMessage id="nav.linkContact" />
              </Nav.Link>
              <Nav.Link href="#section-wholesale">
                <FormattedMessage id="nav.linkWholesale" />
              </Nav.Link>
            </Nav>
            <Nav className="navbar-nav--info">
              <Navbar.Text>
                <IconPhone /> <FormattedMessage id="nav.info" />
              </Navbar.Text>
              <NavDropdown
                title={
                  <>
                    <IconGlobe /> <FormattedMessage id="language" />
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  disabled={LANGUAGE === "sl"}
                  href="https://ardeo.si"
                >
                  Slovenščina
                </NavDropdown.Item>
                <NavDropdown.Item
                  disabled={LANGUAGE === "hr"}
                  href="https://ardeo.hr"
                >
                  Hrvatski
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <section className="section-intro" id="section-intro">
        <video className="video-bg" autoPlay muted loop>
          {false && (
            <source
              src={`${process.env.PUBLIC_URL}video/STV_6201_zelo ok.webm`}
              type="video/webm"
            />
          )}
          }
          <source
            src={`${process.env.PUBLIC_URL}video/bg_${LANGUAGE}.mp4`}
            type="video/mp4"
          />
        </video>
        <div className="section-intro__wrapper">
          <Container>
            <div className="section-intro__text">
              <p className="section-intro__headline h1 text-center">
                <FormattedMessage id="intro.text" />
              </p>
              <div className="text-center">
                <a className="btn btn-primary" href="#section-personalize">
                  <FormattedMessage id="intro.link" />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="section-why" id="section-why">
        <Container>
          <CardDeck>
            <CustomBlock
              title={intl.formatMessage({ id: "why.section1.title" })}
              content={intl.formatMessage({ id: "why.section1.content" })}
              image="images/ardeo/STS_6312.JPG"
            />
            <CustomBlock
              title={intl.formatMessage({ id: "why.section2.title" })}
              content={intl.formatMessage({ id: "why.section2.content" })}
              image={`images/${
                LANGUAGE === "sl" ? "STS_6216_crop.jpg" : "STS_6170_edit.jpg"
              }`}
            />
            <CustomBlock
              title={intl.formatMessage({ id: "why.section3.title" })}
              content={intl.formatMessage({ id: "why.section3.content" })}
              image="images/eko-iniciativa.jpg"
            />
          </CardDeck>
        </Container>
      </section>

      <section className="section-video" id="section-video">
        <Container>
          {YOUTUBE_VIDEO && (
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src={YOUTUBE_VIDEO}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </Container>
      </section>

      <section className="section-used-candles" id="section-used-candles">
        <Container>
          <Row>
            <Col sm={6} md={6}>
              <img
                className="img-fluid"
                src={`${process.env.PUBLIC_URL}images/odpadne sveče.jpeg`}
              />
            </Col>
            <Col sm={6} md={6}>
              <h2>
                <FormattedMessage id="usedCandles.title" />
              </h2>
              <p>
                <FormattedMessage id="usedCandles.content" />
              </p>
              <h2>
                <FormattedMessage id="usedCandles.content2" />
              </h2>
              <p>
                <FormattedMessage id="usedCandles.content3" />
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-teaser" id="section-teaser">
        <Container>
          <div className="section__box">
            <h1 className="section-teaser__title-1 text-center">
              <FormattedMessage id="teaser.title1" />
            </h1>
            <h2 className="section-teaser__title-2 text-center">
              <FormattedMessage id="teaser.title2" />
            </h2>
            <div className="text-center">
              <a className="btn btn-primary" href="#section-personalize">
                <FormattedMessage id="teaser.link" />
              </a>
            </div>
          </div>
        </Container>
      </section>

      <section className="section-personalize" id="section-personalize">
        <Container>
          <h2>
            <FormattedMessage id="personalize.title" />
          </h2>
          <p>
            <FormattedMessage id="personalize.content" />
          </p>
          <ProductList />
        </Container>
      </section>

      <section className="section-reviews" id="section-reviews">
        <div className="section__inner section__inner--reviews">
          <Container>
            <h1 className="text-center">
              <FormattedMessage id="reviews.title" />
            </h1>
            <Reviews />
          </Container>
        </div>
      </section>

      <section className="section-teaser2" id="section-teaser2">
        <Container>
          <div className="section__box">
            <h1 className="section-teaser2__title-1 text-center">
              <FormattedMessage id="teaser2.title1" />
            </h1>
            <h2 className="section-teaser2__title-2 text-center">
              <FormattedMessage id="teaser2.title2" />
            </h2>
            <div className="text-center">
              <a className="btn btn-primary" href="#section-personalize">
                <FormattedMessage id="teaser2.link" />
              </a>
            </div>
          </div>
        </Container>
      </section>

      <section className="section-newsletter-contact">
        <Container>
          <Row>
            <Col md={6}>
              <section className="section-newsletter" id="section-newsletter">
                <h1>
                  <FormattedMessage id="newsletter.title" />
                </h1>
                <p>
                  <FormattedMessage id="newsletter.description" />
                </p>
                <Newsletter />
              </section>
            </Col>
            <Col md={6}>
              <section className="section-contact" id="section-contact">
                <h1>
                  <FormattedMessage id="contact.title" />
                </h1>
              </section>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-wholesale" id="section-wholesale">
        <Container>
          <Row>
            <Col sm={6}>
              <img
                className="img-fluid"
                src={`${process.env.PUBLIC_URL}images/ardeo/STS_6254_crop.JPG`}
              />
            </Col>
            <Col sm={6}>
              <h1>
                <FormattedMessage id="partners.title" />
              </h1>
              <p>
                <FormattedMessage id="partners.content" />
              </p>
              <p>
                <FormattedMessage id="partners.contact" />
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <footer>
        <Container>
          <Row>
            <Col md={6}>
              <h4>
                <FormattedMessage id="footer.title" />
              </h4>
              <p>
                <FormattedMessage id="footer.producer" />
                <br />
                <FormattedMessage id="footer.address1" />
                <br />
                <FormattedMessage id="footer.address2" />
                <br />
                <FormattedMessage id="footer.address3" />
                <br />
                <FormattedMessage id="footer.address4" />
              </p>
              <p>
                <FormattedMessage id="footer.info" />
              </p>

              { CURRENCY === 'HRK' && <p>Službeni tečaj konverzije: 1 € = 7.53450 kn.</p> }
            </Col>
            <Col md={6}>
              <div className="payment-types">
                <FormattedMessage id="footer.paymentTypes" />
                <div className="payment-types__items">
                  {PAYMENT_TYPES.map((item, index) => (
                    <div key={index} className="payment-types__item">
                      <img
                        alt={item.name}
                        title={item.name}
                        src={`${process.env.PUBLIC_URL}${item.image}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="social-icons">
                <div className="social-icons__items">
                  {SOCIAL_ICONS.map((item, index) => (
                    <a
                      key={index}
                      href={item.link}
                      target="_blank"
                      className="social-icons__item"
                    >
                      <img
                        alt={item.name}
                        title={item.name}
                        src={`${process.env.PUBLIC_URL}${item.image}`}
                      />
                    </a>
                  ))}
                </div>
              </div>
              <p>
                <a target="_blank" href={MANUALS_URL}>
                  <FormattedMessage id="footer.manual" />
                </a>
              </p>
            </Col>
          </Row>
          <FormattedMessage id="footer.copyright" />
          Ardeo.si / Informatika Mihelac
        </Container>
      </footer>
      {!cookieAccept && (
        <CookieBar
          onAccept={() => {
            Cookies.set("cookieAccept", new Date());
            setCookieAccept(true);
          }}
        />
      )}
    </div>
  );
};

export default Home;
