import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage, useIntl, FormattedNumber } from "react-intl";
import { newsletterSubscribe } from "../api";

const Newsletter = () => {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      setIsSubmitting(true);
      newsletterSubscribe({ email })
        .then(() => {
          setAlert({
            variant: "success",
            message: (
              <>
                <p>
                  <FormattedMessage id="newsletter.success" />
                </p>
              </>
            ),
          });
          setIsSubmitting(false);
        })
        .catch((err) => {
          setAlert({
            variant: "danger",
            message: intl.formatMessage({
              id: "newsletter.error",
            }),
          });
          setIsSubmitting(false);
        });
    }
    setValidated(true);
  };

  return (
    <div className="newsletter">
      <Form validated={validated} noValidate onSubmit={handleSubmit}>
        {alert && (
          <Alert
            variant={alert.variant}
            onClose={() => setAlert(null)}
            dismissible
          >
            {alert.message}
          </Alert>
        )}

        <Form.Group>
          <Form.Control
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            value={email}
            name="newsletter_email"
            placeholder={intl.formatMessage({ id: "newsletter.fields.email" })}
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="newsletter.errors.email" />
          </Form.Control.Feedback>
        </Form.Group>

        <div className="newsletter__submit">
          <Button
            className="mr-3"
            disabled={isSubmitting}
            type="submit"
            variant="primary"
          >
            <FormattedMessage id="newsletter.submit" />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Newsletter;
