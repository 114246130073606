export const LANGUAGE = 'sl'
export const CURRENCY = "EUR"
export const CART_URL = "https://status.si/zakljucek-nakupa/"

export const PRODUCT_MAP = {
  "R": "36621",
  "B": "36622",
  "Rsymbol": "36621,119119",
  "Rtext":  "36621,119118",
  "Bsymbol":  "36622,119119",
  "Btext":  "36622,119118",
}

export const ORDER_URL = "https://mbsjjgfce3.execute-api.eu-west-1.amazonaws.com/prod"

export const PAYMENT_TYPES = [
  {name: "", image: "images/placilna_metoda_paypal.jpg"},
  {name: "", image: "images/placilna_metoda_povzetju.jpg"},
  {name: "", image: "images/placilna_metoda_upn.jpg"},
  {name: "", image: "images/placilna_metoda_visa.jpg"},
  {name: "", image: "images/mastercard-logo.png"},
]


export const SOCIAL_ICONS = [
  {name: "Facebook", link: "https://www.facebook.com/StatusMetlika/", image: "images/facebook.svg"},
  {name: "Instagram", link: "https://www.instagram.com/status.izvirni.pripomocki/", image: "images/instagram.svg"},
  {name: "Youtube", link: "https://www.youtube.com/user/StatusMetlika", image: "images/youtube.svg"},
  {name: "Email", link: "mailto:info@status.si", image: "images/email.svg"},
]


export const MANUALS_URL = "https://www.status.si/wp-content/uploads/2020/09/Ardeo-elektronski-plamen_SI_avg20.pdf"


const reviews = [
  {
    name: "Mirjana B.",
    stars: 5,
    content:
      "Naročila in zelo zadovoljna. Sveča je zelo lepa in grobu daje poseben pridih pietete do tistih, ki jim je namenjena. Hvala🙏🏻🌷🙏🏻",
  },
  {
    name: "Monika Z.",
    stars: 5,
    content:
      "Zelo vesela in zadovoljna. Nas mali angel je vedno z nami😇",
  },
  {
    name: "Duška T.",
    stars: 5,
    content:
      "Hvala za prečudovito svečo in hvala ker ste na njo napisali moj napis. Resnično je lepa in sem jo že nesla svoji dragi mamici na grob.",
  },
  {
    name: "Polona Z.",
    stars: 5,
    content:
      "Včeraj sem jo dobila. Zelo lepa in resnično sveti kot prava 🥰",
  },
  {
    name: "Vida V.",
    stars: 5,
    content:
      "Naročila sem 2 sveči, ki sem ju danes dobila ❤ Meni so v živo še lepše ❤ Cena pa.... ja itak nikoli ne bo vsem všeč 😉 Tudi zame je sveča, ki jo sama prižgem bolj všeč, vendar pa glede na okolje mislim, da je boljše uporabiti nadomestke. Odločitev je pa predvsem naša 😉",
  },
];

export default reviews

export const MAILERLITE_URL = 'https://4382i17cb5.execute-api.eu-west-1.amazonaws.com/prod'
export const YOUTUBE_VIDEO = "https://www.youtube-nocookie.com/embed/y24Ft0ZV8J0?rel=0"
export const COOKIES_INFO_URL = "https://www.status.si/privacy-policy/"
