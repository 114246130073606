import React from "react";
import PreviewCanvas from "./PreviewCanvas";
import { colorImages } from "../products/ardeo_sl";

const TestPreviewCanvas = () => {
  const image = colorImages[0];
  const [xOffset, setXOffset] = React.useState(image.preview.xOffset);
  const [yOffset, setYOffset] = React.useState(image.preview.yOffset);
  const [text, setText] = React.useState("Počivaj v miru.");
  const newImage = {
    ...image,
    preview: {
      xOffset,
      yOffset,
    },
  };
  return (
    <div>
      <h3>PreviewCanvas tweaks</h3>
      <p>
        <label>xOffset: {xOffset}</label>
        <input
          className="form-control"
          type="range"
          min="0"
          max="500"
          value={xOffset}
          onChange={(e) => setXOffset(parseInt(e.target.value))}
        />
      </p>
      <p>
        yOffset: {yOffset}
        <input
          className="form-control"
          type="range"
          min="0"
          max="500"
          value={yOffset}
          onChange={(e) => setYOffset(parseInt(e.target.value))}
        />
      </p>
      <p>
        text:{" "}
        <input
          className="form-control"
          type="text"
          maxlength="35"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </p>
      <div style={{ width: 800, height: 800, position: "relative" }}>
        <div className="product-gallery">
          <div className="product-gallery__item">
            <img src={`${process.env.PUBLIC_URL}${image.name}`} alt="" />
            <PreviewCanvas image={newImage} text={text} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestPreviewCanvas;
