const regularColorPrice = null;
const colorPrice = 16.5;
const regularSymbolPrice = null;
const symbolPrice = 3;
const regularTextPrice = null;
const textPrice = 3;
const customTextMaxLength = 30;

const imgPath = "/images/ardeo/";

const symbols = [
  {
    name: "Vrtnica",
    image: "/symbols/vrtnica.svg",
    key: "vrtnica",
    images: {
      0: `${imgPath}rdecea_vrtnica_sept22.jpg`,
      1: `${imgPath}bela_vrtnica_sept22.jpg`,
    },
  },
  {
    name: "Golob",
    image: "/symbols/golob.svg",
    key: "golob",
    images: {
      0: `${imgPath}STS_6088.JPG`,
      1: `${imgPath}STS_6098.JPG`,
    },
  },
  {
    name: "Križ",
    image: "/symbols/kriz.svg",
    key: "kriz",
    images: {
      1: `${imgPath}STS_6119.JPG`,
      0: `${imgPath}STS_6127.JPG`,
    },
  },
];

const texts = [
  { key: "1", name: "Pogrešamo te." },
  {
    key: "2",
    name: "Počivaj v miru.",
    images: {
      1: `${imgPath}STS_6036.JPG`,
    },
  },
  { key: "3", name: "Za vedno v naših srcih." },
];

const otherImages = [
  `${imgPath}IMG_20200908_180050.jpg`,
  `${imgPath}STS_6208.JPG`,
  `${imgPath}STS_6246.JPG`,
  `${imgPath}STS_6297.JPG`,
  `${imgPath}STS_6303.JPG`,
  `${imgPath}IMG_20200908_180403.jpg`,
  `${imgPath}STS_6192.JPG`,
  `${imgPath}STS_6229.jpg`,
  `${imgPath}STS_6232.jpg`,
];

const colorR = `${imgPath}Rdeča sveča_prazna_jul20_ok.jpg`;
const colorB = `${imgPath}Bela sveča_angelček_jul20.jpg`;

const makeImages = (items) =>
  items
    .map((symbol) =>
      Object.values(symbol.images || {}).map((name) => ({ name }))
    )
    .flat();

export const textImages = makeImages(texts);
export const colorImages = [
  {
    name: colorR,
    preview: {
      xOffset: 400,
      yOffset: 157,
    },
  },
  {
    name: colorB,
    preview: {
      xOffset: 418,
      yOffset: 167,
    },
  },
];

const product = {
  code: "A",
  colorPrice,
  regularColorPrice,
  priceDescription: null,
  priceDescriptionTooltip: null,
  regularSymbolPrice,
  symbolPrice,
  regularTextPrice,
  textPrice,
  customTextMaxLength,
  images: [
    ...otherImages.map((name) => ({ name })),
    ...colorImages,
    ...makeImages(symbols),
    ...textImages,
  ],
  symbols,
  texts,
  colors: [
    {
      key: "R",
      color: "#f00",
      name: "rdeča",
      image: colorR,
      tooltip: "Gori do 45 dni.",
    },
    {
      key: "B",
      color: "#fff",
      name: "transparentna",
      image: colorB,
      tooltip: "Gori do 60 dni.",
    },
  ],
};

export default product;
