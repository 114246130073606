import React from "react";

import Carousel from "react-bootstrap/Carousel";

import PreviewCanvas from './PreviewCanvas'
import {getTextFromState, getSymbolFromState} from '../utils/product_utils.js'


const ProductGallery = ({ product, state, prefix, index, handleSelect }) => {
  const images = product.images
  const text = getTextFromState(product, state)
  const symbol = getSymbolFromState(product, state)
  const displayPreview = (image, index) => {
    return index === state.imageIndex && image.preview && (text || symbol)
  }
  return (
    <Carousel
      interval={null}
      className="product-gallery"
      activeIndex={index}
      onSelect={handleSelect}
    >
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <div className="product-gallery__item">
            <img src={`${process.env.PUBLIC_URL}${image.name}`} alt="" />
            { displayPreview(image, index) &&
            <PreviewCanvas image={image} text={text} symbol={symbol} />
            }
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ProductGallery;
