import md5 from "md5";
import { CART_URL, PRODUCT_MAP } from "../constants";

export const getColor = (product, state) => product.colors[state.color];
export const getSymbol = (product, state) =>
  state.variationType === "symbol" && product.symbols[state.symbol];

export const getPredefinedTextFromState = (product, state) =>
  state.variationType === "text" && product.texts[state.text]?.name;

export const getCustomTextFromState = (product, state) =>
  state.variationType === "text" && state.text === "custom" && state.customText;

export const getTextFromState = (product, state) => {
  if (state.variationType !== "text" || !state.text) {
    return "";
  }
  return state.text === "custom"
    ? state.customText
    : product.texts[state.text].name;
};

export const getSymbolFromState = (product, state) => {
  return getSymbol(product, state)?.image;
};

export const getPriceFromState = (product, state) => {
  let price = product.colorPrice;
  if (getTextFromState(product, state)) {
    price += product.textPrice;
  }
  if (getSymbol(product, state)) {
    price += product.symbolPrice;
  }
  return price;
};

export const getProductCode = (product, state) => {
  const symbolCode =
    state.variationType === "symbol" &&
    state.symbol &&
    "S" + product.symbols[state.symbol].key;
  let textCode;
  if (state.variationType === "text" && state.text) {
    if (state.text === "custom") {
      textCode = "T" + md5(state.customText).slice(0, 9);
    } else {
      textCode = "T" + product.texts[state.text].key;
    }
  }
  return [
    product.code,
    getColor(product, state).key,
    textCode,
    symbolCode || "",
    `X${state.qty}`,
  ].join("");
};

export const getProductId = (product, state) => {
  const key = [
    getColor(product, state).key,
    getSymbolFromState(product, state) ? "symbol" : "",
    getTextFromState(product, state) ? "text" : "",
  ].join("");
  return PRODUCT_MAP[key];
};

const getCartCodesAndIds = (cartProducts) => {
  let ids = [];
  let codes = [];
  cartProducts.forEach((product) => {
    codes.push(product.code);
    Array.from(Array(parseInt(product.qty)).keys()).forEach(() =>
      ids.push(product.productId)
    );
  });
  return { ids: ids.join(","), codes: codes.join("-") };
};
export const getCartLink = (cartProducts) => {
  let params = new URLSearchParams();
  const { ids, codes } = getCartCodesAndIds(cartProducts);
  params.append("add-to-cart", ids);
  params.append("comment", codes);
  return `${CART_URL}?${params.toString()}`;
};

export const getCartDetails = (values, product, state, id = "") => {
  const products = state.map((productState) => {
    return {
      productId: getProductId(product, productState),
      color: getColor(product, productState).name,
      symbol: getSymbol(product, productState).name,
      text: getPredefinedTextFromState(product, productState),
      customText: getCustomTextFromState(product, productState),
      qty: productState.qty,
      price: getPriceFromState(product, productState),
      total: getPriceFromState(product, productState) * productState.qty,
      code: getProductCode(product, productState) + id,
    };
  });
  return {
    ...values,
    products,
    code: getCartCodesAndIds(products).codes,
    url: getCartLink(products),
  };
};
