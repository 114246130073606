import React from "react";

const width = 1000;
const height = width / 1.58;

const lerp = (x, y, a) => x * (1 - a) + y * a;

const getFontSize = (text) => {
  return lerp(128, 40, text.length / 35);
};

const wrapImage = (ctx, img, xOffset, yOffset) => {
  var iw = img.width;
  var ih = img.height;

  var a = 95;
  //image width
  var b = 10;
  //round ness

  var scaleFactor = iw / (3 * a);
  //console.log(iw, ih, a, scaleFactor);

  // draw vertical slices
  for (var X = 0; X < iw; X += 1) {
    var y = (b / a) * Math.sqrt(a * a - (X - a) * (X - a));
    // ellipsis equation
    ctx.drawImage(
      img,
      X * scaleFactor,
      0,
      iw / 3,
      ih,
      X + xOffset,
      y + yOffset,
      1,
      200
    );
  }
};

const draw = (canvas, workCanvasRef, image, text, symbol) => {
  let workCanvas;
  if (!workCanvasRef.current) {
    workCanvas = document.createElement("canvas");
    workCanvas.width = width;
    workCanvas.height = height;
    workCanvasRef.current = workCanvas;
  } else {
    workCanvas = workCanvasRef.current;
  }
  const ctx = workCanvas.getContext("2d");
  const previewCtx = canvas.getContext("2d");
  previewCtx.clearRect(0, 0, width, height);
  ctx.clearRect(0, 0, width, height);
  if (false && !process.env.production) {
    ctx.fillStyle = "#ff00ff99";
    ctx.fillRect(0, 0, width, height);
  }

  const drawImage = () =>
    wrapImage(
      previewCtx,
      workCanvas,
      image.preview.xOffset,
      image.preview.yOffset
    );

  if (text) {
    const fontSize = getFontSize(text);
    ctx.font = `expanded bold ${fontSize}px  'Franklin Gothic'`;
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fillStyle = "#00000099";
    ctx.fillText(text, width / 2, 80);
    drawImage();
  } else {
    const symbolImg = new Image();
    symbolImg.src = `${process.env.PUBLIC_URL}${symbol}`;
    symbolImg.onload = () => {
      ctx.drawImage(symbolImg, image.preview.xOffset, image.preview.yOffset);
      drawImage();
    };
  }
};

const PreviewCanvas = ({ image, text, symbol }) => {
  const canvasRef = React.useRef(null);
  const workCanvasRef = React.useRef(null);
  React.useEffect(() => {
    draw(canvasRef.current, workCanvasRef, image, text, symbol);
  });
  return <canvas ref={canvasRef} width={width} height={height} />;
};

export default PreviewCanvas;
