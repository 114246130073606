import React from "react";
import { FormattedNumber } from "react-intl";

const toHrk = (val) => val * 7.5345;
const PriceEur = ({ value }) => (
  <>
    {" "}
    (<FormattedNumber value={toHrk(value)} style="currency" currency="HRK" />)
  </>
);

export default PriceEur;
