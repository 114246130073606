import React from "react";
import classNames from "classnames";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { CURRENCY, LANGUAGE } from "../constants";
import { ReactComponent as IconInfo } from "./icons/info.svg";

import ProductGallery from "./ProductGallery";
import PriceEur from "./PriceEur";

const numOptions = [1, 2, 3, 4, 5];

const OptionPrice = ({ value, disabled, optional }) => (
  <div className="option-price">
    {optional && "+"}
    <FormattedNumber value={value} style="currency" currency={CURRENCY} />
    { LANGUAGE === 'hr' && <PriceEur value={value} /> }
  </div>
);

const RegularPrice = ({ regularPrice }) => (
  <span className="regular-price mr-1">
    <FormattedNumber
      value={regularPrice}
      style="currency"
      currency={CURRENCY}
    />
    { CURRENCY === 'HRK' && <PriceEur value={regularPrice} /> }
  </span>
);

const VariationBlock = ({
  prefix,
  current,
  variationType = null,
  label,
  price,
  regularPrice,
  priceDescription,
  priceDescriptionTooltip,
  optional,
  children,
  className,
}) => {
  const newClassName = classNames("product__variation ", className, {
    "product__variation--inactive": variationType && variationType !== current,
  });
  return (
    <Form.Group className={newClassName}>
      <div className="product__variation-and-price d-flex  flex-wrap justify-content-between">
        <label className="product__variation-label">{label}: </label>
        <div className="d-flex">
          {price > 0 && regularPrice && (
            <RegularPrice regularPrice={regularPrice} />
          )}
          {price ? <OptionPrice optional={optional} value={price} /> : null}
          {priceDescription && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{priceDescriptionTooltip}</Tooltip>}
            >
              <Form.Label>
                <span className="ml-1">&nbsp;{priceDescription}&nbsp;</span>
              </Form.Label>
            </OverlayTrigger>
          )}
        </div>
      </div>
      {children}
    </Form.Group>
  );
};

const Personalization = ({
  prefix,
  product,
  setValue,
  setValueAndFocusPreview,
  state,
}) => {
  const intl = useIntl();
  const variationBlockProps = {
    current: state.variationType,
    prefix,
  };
  const variationTypeSymbol = state.variationType === "symbol";
  const variationTypeText = state.variationType === "text";

  return (
    <div className="product__personalization d-flex flex-fill">
      <div className="d-flex flex-column flex-fill justify-content-between">
        <div>
          <VariationBlock
            label={intl.formatMessage({ id: "personalization.color" })}
            price={product.colorPrice}
            regularPrice={product.regularColorPrice}
            priceDescription={product.priceDescription}
            priceDescriptionTooltip={product.priceDescriptionTooltip}
            {...variationBlockProps}
          >
            {product.colors.map((color, index) => (
              <Form.Check
                custom
                inline
                name={`${prefix}color`}
                id={`${prefix}color-${color.key}`}
                key={color.key}
                label={
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{color.tooltip}</Tooltip>}
                  >
                    <Form.Label>{color.name}</Form.Label>
                  </OverlayTrigger>
                }
                checked={state.color === index.toString()}
                value={index}
                type="radio"
                onChange={setValueAndFocusPreview}
                disabled={color.disabled}
              />
            ))}
          </VariationBlock>

          <VariationBlock
            variationType="symbol"
            optional={true}
            label={intl.formatMessage({ id: "personalization.symbol" })}
            price={
              variationTypeSymbol && state.symbol ? product.symbolPrice : 0
            }
            regularPrice={product.regularSymbolPrice}
            {...variationBlockProps}
          >
            <div className="product__symbols">
              {product.symbols.map((symbol, index) => {
                const selected = state.symbol === index.toString();
                return (
                  <div key={symbol.key}>
                    <img
                      className={classNames("product__symbol", {
                        "product__symbol--selected": selected,
                      })}
                      src={symbol.image}
                      alt={symbol.name}
                      onClick={(e) =>
                        setValueAndFocusPreview(
                          e,
                          `symbol`,
                          selected ? "" : "" + index
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          </VariationBlock>

          <VariationBlock
            variationType="text"
            label={intl.formatMessage({ id: "personalization.text" })}
            price={variationTypeText && state.text ? product.textPrice : 0}
            regularPrice={product.regularTextPrice}
            optional={true}
            {...variationBlockProps}
          >
            <Form.Group controlId="customText">
              <Form.Control
                name={`${prefix}text`}
                as="select"
                custom
                value={state.text}
                onChange={setValueAndFocusPreview}
              >
                <option value="">
                  {intl.formatMessage({ id: "personalization.text.choose" })}
                </option>
                {product.texts.map((text, index) => (
                  <option key={text.key} value={index}>
                    {text.name}
                  </option>
                ))}
                <option value="custom">
                  {intl.formatMessage({ id: "personalization.text.custom" })}
                </option>
              </Form.Control>
            </Form.Group>
            {variationTypeText && state.text === "custom" && (
              <Form.Group controlId="customText">
                <Form.Label>
                  <FormattedMessage id="personalization.text.customLabel" />{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        <FormattedMessage id="personalization.text.customInfo" />
                      </Tooltip>
                    }
                  >
                    <IconInfo />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={setValueAndFocusPreview}
                  name={`${prefix}customText`}
                  value={state.customText}
                  maxLength={product.customTextMaxLength}
                />
              </Form.Group>
            )}
          </VariationBlock>
        </div>

        <div>
          <VariationBlock
            label={intl.formatMessage({ id: "personalization.num" })}
            className="mb-0"
            {...variationBlockProps}
          >
            <Form.Control
              as="select"
              custom
              onChange={setValue}
              name={`${prefix}qty`}
              value={state.qty}
            >
              {numOptions.map((index) => (
                <option key={index} value={index}>
                  {index}
                </option>
              ))}
            </Form.Control>
          </VariationBlock>
        </div>
      </div>
    </div>
  );
};

const Product = ({
  onDelete,
  prefix,
  state,
  product,
  setValue,
  setValueAndFocusPreview,
  setImageIndex,
}) => {
  return (
    <div className="product mb-3">
      {onDelete && (
        <div className="mb-3 text-right">
          <Button
            className="uppercase"
            variant="outline-primary"
            size="sm"
            onClick={onDelete}
          >
            <FormattedMessage id="product.delete" />
          </Button>
        </div>
      )}
      <div>
        <Row>
          <Col className="mb-3" lg={8} sm={6}>
            <ProductGallery
              prefix={prefix}
              state={state}
              product={product}
              handleSelect={setImageIndex}
              index={state.imageIndex}
            />
          </Col>
          <Col className="mb-3 d-flex" lg={4} sm={6}>
            <Personalization
              prefix={prefix}
              state={state}
              product={product}
              setValue={setValue}
              setValueAndFocusPreview={setValueAndFocusPreview}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Product;
