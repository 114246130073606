import {
  ORDER_URL,
  LANGUAGE,
  MAILERLITE_URL,
} from "../constants";

export const submitOrder = async (cart) => {
  const response = await fetch(`${ORDER_URL}?language=${LANGUAGE}`, {
    method: "POST",
    body: JSON.stringify(cart),
  });
  return response;
};

export const newsletterSubscribe = async (data) => {
  const response = await fetch(
    MAILERLITE_URL,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    }
  );
  if (!response.ok) {
    throw Error(response.statusText);
  }
};
