import React from "react";
import { IntlProvider } from "react-intl";
import Container from "react-bootstrap/Container";

import Home from "./pages/Home";

import "./stylesheets/custom.scss";

const language = process.env.REACT_APP_LANGUAGE || 'sl'
const messages = require(`./locales/${language}.json`)

const constants = require('./constants/index')

const TestPreviewCanvas = process.env.REACT_APP_PREVIEW
  ?  require("./components/TestPreviewCanvas").default : null;

function App() {
  return (
    <IntlProvider messages={messages} locale={language} defaultLocale={language}>
        {TestPreviewCanvas ? <TestPreviewCanvas /> : <Home/> }
    </IntlProvider>
  );
}

export default App;
