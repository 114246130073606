export const initialProductState = {
  color: "0",
  symbol: null,
  text: "",
  customText: "",
  variationType: null,
  imageIndex: 0,
  qty: 1,
};
export const initialState = [initialProductState];

const replaceValueInArray = (arr, num, val) => {
  const newArr = arr.slice();
  newArr[num] = val;
  return newArr;
};

const setValue = (arr, num, key, val) => {
  return replaceValueInArray(arr, num, {
    ...arr[num],
    [key]: val,
  });
};

const findProductImageIndex = (product, name) => {
    return product.images.findIndex(
      image => image.name === name
    )
}

export const getImageIndexFromState = (product, productState) => {
  const color = productState.color
  if (!color) {
    return null;
  }
  if (productState.symbol) {
    return findProductImageIndex(product, product.symbols[productState.symbol].images[color])
  }
  if (productState.text && productState.text !== 'custom') {
    const images = product.texts[productState.text].images
    if (images && images[color]) {
      return findProductImageIndex(product, images[color])
    }
  }
  return findProductImageIndex(product, product.colors[color].image)
};

const variationTypeParams = ['text', 'customText', 'symbol']

export default function reducer(state, action) {
  switch (action.type) {
    case "setValue": {
      const newState = setValue(state, action.index, action.name, action.value);
      const productState = newState[action.index]
      if (variationTypeParams.indexOf(action.name) !== -1) {
        let variationType = null
        if (action.name === 'text' && action.value !== '') {
          variationType = 'text'
          productState.symbol = ""
        } else if (action.name === 'customText') {
          variationType = 'text'
          productState.symbol = ""
        } else if (action.name === 'symbol' && action.value) {
          variationType = 'symbol'
          productState.text = ""
        } else {
          variationType = null
        }
        productState.variationType = variationType
      }
      if (action.focusPreview) {
        newState[action.index].imageIndex = getImageIndexFromState(
          action.product,
          newState[action.index]
        );
      }
      return newState;
    }
    case "addProduct":
      return [...state, initialProductState];
    case "deleteProduct": {
      const newState = state.slice();
      newState.splice(action.index, 1);
      return newState;
    }
    default:
      throw new Error();
  }
}
