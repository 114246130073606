export const LANGUAGE = 'hr'
export const CURRENCY = "EUR"
export const CART_URL = "https://www.status-trgovina.hr/stranica-naplate/"

export const PRODUCT_MAP = {
  "R": "21792",
  "B": "21793",
  "Rsymbol": "21792,42698",
  "Rtext":  "21792,42699",
  "Bsymbol":  "21793,42698",
  "Btext":  "21793,42699",
}

export const ORDER_URL = "https://mbsjjgfce3.execute-api.eu-west-1.amazonaws.com/prod"

export const PAYMENT_TYPES = [
  {name: "", image: "images/placilna_metoda_povzetju.jpg"},
]


export const SOCIAL_ICONS = [
  {name: "Facebook", link: "https://www.facebook.com/StatusKarlovac/", image: "images/facebook.svg"},
  {name: "Instagram", link: "https://www.instagram.com/status.izvorna.pomagala/", image: "images/instagram.svg"},
  {name: "Youtube", link: "https://www.youtube.com/user/StatusMetlika", image: "images/youtube.svg"},
  {name: "Email", link: "mailto:info@status.si", image: "images/email.svg"},
]


export const MANUALS_URL = "https://www.status-trgovina.hr/wp-content/uploads/2020/09/Ardeo-elektronski-plamen_HR_avg20_Final_2_outlines.pdf"


const reviews = [
  {
    name: "Maja L.",
    stars: 5,
    content:
      "Mami sam željela na grob dati nešto posebno što ne izgori u jednom tjednu. Zna da mislim na nju i da mi puno znači. To sam i napisala na svijeću. Tako joj uvijek svijetli svijetlo.",
  },
  {
    name: "Igor G.",
    stars: 5,
    content:
      "Sviđa mi se da ne trebam za svaki praznik kupiti lampion. Uzmem samo baterije te ih povremeno zamijenim. Svijetli već pola godine i ako preračunam koliko običnih svijeća bi već potrošio, onda sam puno uštedio.",
  },
  {
    name: "Kristina H.",
    stars: 5,
    content:
      "Baki sam na grob uvijek nosila običnu svijeću. Kad sam se zamislila koliko smeća sam već napravila i kako ružno izgleda plastika nakon nekoliko dana, skužila sam da je treba nešto promijeniti. Našla sam Ardeo i bakin grob je sad uvijek uređen. Znam da bi se i njoj sviđalo kako brinem za okoliš. ",
  },
  {
    name: "Renata Š.",
    stars: 4,
    content:
      "Sviđa mi se dizajn i svrha svijeće Ardeo. Nadam se da će u buduće biti u prodaji i u kojoj većoj trgovini. ",
  },
];

export default reviews


export const MAILERLITE_URL = 'https://3aah6zu5qa.execute-api.eu-west-1.amazonaws.com/prod'

export const YOUTUBE_VIDEO =  "https://www.youtube-nocookie.com/embed/XMzFge2yt3o?rel=0"
export const COOKIES_INFO_URL = "https://www.status-trgovina.hr/privacy-policy/"
